
import AtomIcon from '@/components/atoms/common/AtomIcon'
import baseConstants from '~/store/base/-constants'
export default {
  name: 'AtomLocaleSwitch',
  components: { AtomIcon },
  data() {
    let locale = ''
    if (typeof window !== 'undefined') {
      locale = localStorage.getItem('locale')
    }

    return {
      locale: locale || 'fi',
      languages: [
        {
          locale: 'fi',
          text: 'Finnish'
        },
        {
          locale: 'en',
          text: 'English'
        }
      ]
    }
  },
  mounted() {
    if (this.locale) {
      this.$i18n.locale = this.locale
    }
  },
  watch: {
    locale: function (val) {
      this.$i18n.locale = val
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.CHANGE_LANGUAGE), val)
      localStorage.setItem('locale', val)
      this.$cookiz.set('langCode', val)
    }
  }
}
